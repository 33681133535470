.productDetailOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.productDetailContent {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.closeButton {
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0.5rem;
}

.productImageContainer {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.productImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imagePlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  color: #666;
}

.productInfo {
  padding: 2rem;
}

.productTitle {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
}

.productDescription {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.productDetails {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.detailSection {
  flex: 1;
}

.detailSection h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 1rem;
}

.detailSection ul {
  list-style-type: none;
  padding: 0;
}

.detailSection li {
  margin-bottom: 0.5rem;
  position: relative;
  padding-left: 1.5rem;
}

.detailSection li::before {
  content: '•';
  color: #4CAF50;
  position: absolute;
  left: 0;
}

.contactButton {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contactButton:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .productDetailContent {
    width: 95%;
  }

  .productDetails {
    flex-direction: column;
    gap: 1rem;
  }

  .productImageContainer {
    height: 200px;
  }

  .productInfo {
    padding: 1rem;
  }

  .productTitle {
    font-size: 1.5rem;
  }
}